import { keyBy } from "@core/types/utils/jsUtils";

export interface DetectionServer {
  id: number;
  name: string;
  label: string;
  url: string;
  socketUrl?: string;
  downloadUrl: string;
  adminUrl?: string;
  inactive?: boolean;
}

export type DetectionServerExternal = Pick<
  DetectionServer,
  "url" | "name" | "label" | "socketUrl"
>;

/**
 *  represents the minimal server info for requests
 */
export type DetectionServerInfo = Pick<
  DetectionServer,
  "name" | "url" | "label" | "adminUrl" | "socketUrl"
>;

type AvailableServers = "S5" | "S6" | "S7" | "L1";

export const detectionServers: Record<AvailableServers, DetectionServer> = {
  /** @deprecated not used anymore */
  S5: {
    id: 1,
    name: "s5",
    label: "S5",
    url: "https://s5api.onlinecontrolserver.net",
    downloadUrl: "https://s5.onlinecontrolserver.net",
    inactive: true,
  },
  S6: {
    id: 2,
    name: "s6",
    label: "S6",
    url: "https://s6api.onlinecontrolserver.net",
    // socketUrl: "socks-s6.onlinecontrolserver.net",
    downloadUrl: "https://s6.onlinecontrolserver.net",
    adminUrl: "https://s6admin.onlinecontrolserver.net",
  },
  L1: {
    id: 3,
    name: "l1",
    label: "L1",
    url: "https://l1api.connecting.ai",
    // socketUrl: "socks.connecting.ai",
    downloadUrl: "https://l1api.connecting.ai",
    adminUrl: "https://l1admin.connecting.ai",
    inactive: true,
  },
  S7: {
    id: 4,
    name: "s7",
    label: "S7",
    url: "https://s7api.logodetect.com",
    downloadUrl: "https://s7.logodetect.com",
    adminUrl: "https://s7admin.logodetect.com",
  },
};

export const DetectionServer = {
  S6: detectionServers.S6,
  // L1: detectionServers.L1,
  S7: detectionServers.S7,
};

const allDetectionServers: DetectionServer[] = Object.values(DetectionServer);

export const activeDetectionServers = allDetectionServers.filter(
  (s) => !s.inactive
);
export const detectionServerById = keyBy(allDetectionServers, (s) => s.id);
export const detectionServerByName = keyBy(allDetectionServers, (s) => s.name);
export const defaultDetectionServer = detectionServerByName["s6"];
